import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { SearchOutlined } from '@ant-design/icons'
import { useVariant } from '@unleash/proxy-client-react'
import { Skeleton } from 'antd'
import { DatePicker, Image, Select, TextInput } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { OcrDocumentInformationResponse } from '@/App/clients/OCR/mutation/fillDeclarationPermanence'
import { DocumentDto, Entity } from '@/App/clients/healthPlansOrders/dtos'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useInsurerFields } from '@/App/clients/insurerEdge/queries/getFields'
import { findClosestMatch } from '@/App/helpers/issuingAgenyMatch'
import { assembleInsurer } from '@/App/utils/assembleInsurer'
import { formatDate } from '@/App/utils/formatDate'
import { toCamelCase } from '@/App/utils/string'

import { EntityType } from '../../..'
import { generateHiredPlansArray } from '../../../helper/helper'
import { insurersSulamerica } from '../../../helper/sulamerica/insurers'
import {
  FormItem,
  FormItemLabel,
  ImageInspector,
  SearchIconDiv,
  StyledFigure,
  StyledForm,
  StyledOCRView,
  StyledWrapper,
} from '../style'

export interface OnFileUploadInput {
  fileContent: Blob | string
  fileContentType: string
  fileName: string
  reference?: string
}

export interface OCRDocumentsProps {
  isInspectorOpen: boolean
  setInspectorOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>
  documentUploaded?: DocumentDto
  entityData?: Entity | null
  OCRData?: OcrDocumentInformationResponse
  OCRLoading: boolean
  orderId?: string | null
  isViewOcrActivated: boolean
  insurer?: string | null
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
  setDocumentUploaded: React.Dispatch<React.SetStateAction<DocumentDto | undefined>>
  reloadOrderData?: () => void
  isOcrProcessing: boolean
  setIsOcrProcessing: React.Dispatch<React.SetStateAction<boolean>>
  documentType: string
  entityType: EntityType
}
export const OCRDeclarationPermanence: React.FC<OCRDocumentsProps> = ({
  isInspectorOpen,
  setInspectorOpen,
  documentUploaded,
  entityData,
  OCRData,
  OCRLoading,
  orderId,
  isViewOcrActivated,
  setVisibility,
  setDocumentUploaded,
  reloadOrderData,
  setIsOcrProcessing,
  documentType,
  insurer,
  entityType,
}) => {
  const [imagePreviewVisibility, setImagePreviewVisibility] = useState(false)

  const fillOrderInformationSaveMutation = useFillOrderInformation()
  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const formattedInsurer = toCamelCase(assembleInsurer(insurer))
  const insurerEdge = useVariant('bliss-intranet-insurer-edge')?.payload?.value
  const insurerEdgeFlags = JSON.parse(insurerEdge ?? '{}')
  const isInsurerEdgeEnabled =
    insurerEdgeFlags[formattedInsurer ?? ''] || insurerEdgeFlags['default'] || false

  const insurerFieldsQuery = useInsurerFields({
    fieldType: 'DROPDOWN',
    insurerId: orderInformationQuery?.data?.proposalIssued
      ? '000'
      : orderInformationQuery?.data?.quotation?.insurerId,
    product: orderInformationQuery?.data?.quotation?.product,
    modality: orderInformationQuery?.data?.quotation?.type,
    issuedByBroker: orderInformationQuery?.data?.proposalIssued ? 1 : 0,
    enabled: isInsurerEdgeEnabled,
  })

  const plansInsurer = insurer === 'Amil' ? 'activeHiredPlanInsurer' : 'previousPlan.insurer'

  const insurers =
    formattedInsurer === 'sulamerica'
      ? insurersSulamerica
      : insurerFieldsQuery.data
          ?.find((field) => field.name === plansInsurer)
          ?.options.filter((o) => o.type.toUpperCase() === entityType)
          .map((o) => ({ label: o.intranetValue, value: o.blissValue }))

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<Entity>({
    defaultValues: entityData ?? {},
    mode: 'onSubmit',
  })

  const healthOrDental =
    documentType === 'healthDeclarationPermanence' ? 'hiredHealthPlan' : 'hiredDentalPlan'

  useEffect(() => {
    if (OCRData != undefined) {
      setValue(`${healthOrDental}.registrationNumber`, OCRData['card-number'])
      setValue(
        `${healthOrDental}.activeHiredPlanInsurer`,
        findClosestMatch(OCRData.operator, insurers ?? []),
      )
      setValue(
        `${healthOrDental}.hiredAt`,
        formatDate(OCRData['effective-date']) as unknown as Date,
      )
      setValue(
        `${healthOrDental}.lastPaymentAt`,
        formatDate(OCRData['end-date']) as unknown as Date,
      )
      setValue(`${healthOrDental}.isActive`, true)
    }
  }, [OCRData])

  const fillEntityInformation = async (data: Entity) => {
    delete data.quotationPlans
    const payload = {
      entities: [
        {
          id: entityData?.id,
          hiredPlans: generateHiredPlansArray(data, entityData, documentType),
        },
      ],
    }

    fillOrderInformationSaveMutation.mutate({ ...payload, orderId: orderId })
    setIsOcrProcessing(true)
  }

  useEffect(() => {
    if (fillOrderInformationSaveMutation.isSuccess) {
      setVisibility(false)
      setDocumentUploaded(undefined)
      setInspectorOpen(false)
      reloadOrderData && reloadOrderData()
    }
  }, [fillOrderInformationSaveMutation.isSuccess])

  useEffect(() => {
    if (fillOrderInformationSaveMutation.isError) {
      triggerToast(false, 'Ops algo deu errado, verifique as informações enviadas!')
      setIsOcrProcessing(false)
    }
  }, [fillOrderInformationSaveMutation.isError])

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      triggerToast(false, 'Preencha os campos obrigatórios!')
    }
  }, [errors])

  const requiredMessage = 'Campo obrigatório'

  return (
    <>
      {OCRLoading && <Skeleton />}
      {(isInspectorOpen || isViewOcrActivated) && (
        <StyledWrapper>
          <StyledOCRView>
            <ImageInspector>
              <StyledFigure>
                {documentUploaded?.fileName?.split('.').pop() === 'pdf' ? (
                  <embed src={documentUploaded?.previewURL} width={450} height={380} />
                ) : (
                  <Image
                    height={'360px'}
                    src={documentUploaded?.previewURL ?? ''}
                    visible={imagePreviewVisibility && !!documentUploaded?.previewURL}
                    setVisible={setImagePreviewVisibility}
                  />
                )}
              </StyledFigure>
              <SearchIconDiv>
                {!(documentUploaded?.fileName?.split('.').pop() === 'pdf') && (
                  <SearchOutlined onClick={() => setImagePreviewVisibility(true)} />
                )}
              </SearchIconDiv>
            </ImageInspector>
          </StyledOCRView>
          <StyledForm id="OCR" onSubmit={handleSubmit(fillEntityInformation)}>
            <FormItem>
              <TextInput
                name={`${healthOrDental}.registrationNumber`}
                placeholder="Nº de matrícula"
                label="Matrícula*"
                vertical
                register={{
                  ...register(`${healthOrDental}.registrationNumber`, {
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }),
                }}
                error={
                  documentType === 'healthDeclarationPermanence'
                    ? errors?.hiredHealthPlan?.registrationNumber
                    : errors?.hiredDentalPlan?.registrationNumber
                }
              />
            </FormItem>

            <FormItem>
              <FormItemLabel>Operadora atual*</FormItemLabel>
              <Select
                name={`${healthOrDental}.activeHiredPlanInsurer`}
                control={control}
                placeholder="Selecione a operadora atual"
                showSearch={true}
                disabled={insurers && insurers.length === 0}
                options={insurers ?? []}
                rules={{
                  required: requiredMessage,
                  setValueAs: (value) => (value ? value : null),
                }}
              />
            </FormItem>

            <FormItem>
              <FormItemLabel>Data de Início*</FormItemLabel>
              <DatePicker
                name={`${healthOrDental}.hiredAt`}
                control={control}
                rules={{
                  required: requiredMessage,
                }}
              />
            </FormItem>

            <FormItem>
              <FormItemLabel>Data do Último Pagamento*</FormItemLabel>
              <DatePicker
                name={`${healthOrDental}.lastPaymentAt`}
                control={control}
                rules={{
                  required: requiredMessage,
                }}
              />
            </FormItem>
          </StyledForm>
        </StyledWrapper>
      )}
    </>
  )
}
