export const findClosestMatch = (
  input: string,
  list: { label: string; value: string }[],
): string | null => {
  if (!input) return null
  const normalizedInput = input.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()

  const exactMatch = list.find(
    (item) => item.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() === normalizedInput,
  )
  if (exactMatch) return exactMatch.label

  const partialMatch = list.find((item) =>
    item.value
      .replace(/[^a-zA-Z0-9]/g, '')
      .toUpperCase()
      .includes(normalizedInput),
  )
  if (partialMatch) return partialMatch.label

  return list[0]?.label || 'Nenhuma correspondência encontrada'
}
