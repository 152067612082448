/* eslint-disable react/prop-types */
import { useEffect } from 'react'

import { Button, Toast } from 'design-system/components'
import Exclamation from 'design-system/exclamation'

import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'
import { useOrderValidationMutation } from '@/App/clients/validationJob/mutation/dispatchValidation'

import { ButtonDiv, CancelButton, ModalContent, ModalSubTitle, ModalTitle } from './style'

interface dataConfirmationProps {
  setIsModalOpen?: (value: boolean) => void
  orderId?: string | null
  setOrderStatus?: React.Dispatch<React.SetStateAction<string | null>>
  reloadOrderData?: () => void
}

export const DataConfirmationModal: React.FC<dataConfirmationProps> = ({
  setIsModalOpen,
  setOrderStatus,
  orderId,
  reloadOrderData,
}) => {
  const validationDispatch = useOrderValidationMutation()

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const updateStatusMutation = useUpdateStatus(orderId)

  useEffect(() => {
    if (validationDispatch.isSuccess || validationDispatch.isError) {
      const currentStatus = 'DATA_CONFIRMATION'

      if (orderInformationQuery?.data?.status !== currentStatus) {
        updateStatusMutation.mutateAsync({
          nextStatus: currentStatus,
          order: orderInformationQuery?.data,
        })
        setOrderStatus && setOrderStatus(currentStatus)
      }

      setIsModalOpen && setIsModalOpen(false)
    }
  }, [validationDispatch.isSuccess, validationDispatch.isError])

  useEffect(() => {
    if (updateStatusMutation.isSuccess) {
      reloadOrderData && reloadOrderData()
    }
  }, [updateStatusMutation.isSuccess])

  return (
    <ModalContent>
      <Exclamation />
      <ModalTitle>Tem certeza que deseja confirmar os dados com o cliente?</ModalTitle>
      <ModalSubTitle>
        Ao solicitar, a Fátima automaticamente enviará uma mensagem para o cliente com os dados para
        confirmação. Campos obrigatórios não preenchidos também serão solicitados ao cliente,
        certifique-se de preencher todos os campos possíveis.
      </ModalSubTitle>

      <ButtonDiv>
        <Button
          loading={validationDispatch.isPending}
          onClick={() => {
            const dispatchData = { orderId, isAutomaticValidation: true }
            validationDispatch.mutate(dispatchData)
          }}
        >
          Solicitar Confirmação
        </Button>
        <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>
          Cancelar
        </CancelButton>
      </ButtonDiv>
      <Toast />
    </ModalContent>
  )
}
