export enum OrderStatus {
  FILLING_IN_PROGRESS = 'FILLING_IN_PROGRESS',
  OPPORTUNITY_FILLED = 'OPPORTUNITY_FILLED',
  FILLED = 'FILLED',
  CUSTOMER_ACCEPTANCE = 'CUSTOMER_ACCEPTANCE',
  INSURER_PROCESSING = 'INSURER_PROCESSING',
  WAITING_SIGNATURE = 'WAITING_SIGNATURE',
  READY_TO_DISPATCH = 'READY_TO_DISPATCH',
  ANALYSIS = 'ANALYSIS',
  QUALIFIED_INTERVIEW = 'QUALIFIED_INTERVIEW',
  FILLING_NOT_MEET_REQUIREMENTS = 'FILLING_NOT_MEET_REQUIREMENTS',
  EFFECTIVE_DATE_PENDING = 'EFFECTIVE_DATE_PENDING',
  WAITING_BILL = 'WAITING_BILL',
  BILL_PAYMENT_PENDING = 'BILL_PAYMENT_PENDING',
  HIRED = 'HIRED',
  CANCELED = 'CANCELED',
  NOT_MAPPED = 'NOT_MAPPED',
  DISPATCH_IN_PROGRESS = 'DISPATCH_IN_PROGRESS',
  QUOTATION_INSURER = 'QUOTATION_INSURER',
  RISK_ANALYSIS = 'RISK_ANALYSIS',
  DISPATCH_APPROVED = 'DISPATCH_APPROVED',
  PENDING_DISPATCH = 'PENDING_DISPATCH',
  DATA_CONFIRMATION = 'DATA_CONFIRMATION',
}
