import React from 'react'

import {
  BellFilled,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditFilled,
  InfoCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'

import { BrokerStatus } from '../../enum/brokerStatus'
import { OrderStatus } from '../../enum/orderStatus'
import { StyledTag } from './style'

export interface Props {
  status: string
  type?: 'order' | 'broker'
}

const orderStatus = {
  [OrderStatus.FILLING_IN_PROGRESS]: {
    icon: <EditFilled />,
    color: 'geekblue',
    text: 'Em preenchimento',
  },
  [OrderStatus.OPPORTUNITY_FILLED]: {
    icon: <EditFilled />,
    color: 'geekblue',
    text: 'Oportunidade preenchida',
  },
  [OrderStatus.DATA_CONFIRMATION]: {
    icon: <CloseOutlined />,
    color: 'orange',
    text: 'Confirmação de dados',
  },
  [OrderStatus.FILLED]: {
    icon: <CheckOutlined />,
    color: 'geekblue',
    text: 'Preenchido',
  },
  [OrderStatus.RISK_ANALYSIS]: {
    icon: <BellFilled />,
    color: 'orange',
    text: 'Em análise de risco',
  },
  [OrderStatus.QUOTATION_INSURER]: {
    icon: <SettingOutlined />,
    color: 'pink',
    text: 'Cotação Operadora',
  },
  [OrderStatus.DISPATCH_APPROVED]: {
    icon: <CheckOutlined />,
    color: 'geekblue',
    text: 'Aprovado para emissão',
  },
  [OrderStatus.PENDING_DISPATCH]: {
    icon: <BellFilled />,
    color: 'orange',
    text: 'Pendente emissão',
  },
  [OrderStatus.INSURER_PROCESSING]: {
    icon: <EditFilled />,
    color: 'pink',
    text: 'Em emissão na operadora',
  },
  [OrderStatus.CUSTOMER_ACCEPTANCE]: {
    icon: <UserOutlined />,
    color: 'purple',
    text: 'Preenchimento DS e Aceite',
  },
  [OrderStatus.READY_TO_DISPATCH]: {
    icon: <BellFilled />,
    color: 'orange',
    text: 'Liberar na operadora',
  },
  [OrderStatus.ANALYSIS]: {
    icon: <ClockCircleOutlined />,
    color: 'pink',
    text: 'Proposta em análise',
  },
  [OrderStatus.QUALIFIED_INTERVIEW]: {
    icon: <UserOutlined />,
    color: 'purple',
    text: 'Entrevista qualificada',
  },
  [OrderStatus.FILLING_NOT_MEET_REQUIREMENTS]: {
    icon: <BellFilled />,
    color: 'orange',
    text: 'Pendência na proposta',
  },
  [OrderStatus.EFFECTIVE_DATE_PENDING]: {
    icon: <BellFilled />,
    color: 'orange',
    text: 'Data de vigência pendente',
  },
  [OrderStatus.WAITING_SIGNATURE]: {
    icon: <UserOutlined />,
    color: 'purple',
    text: 'Assinatura do contrato',
  },
  [OrderStatus.WAITING_BILL]: {
    icon: <ClockCircleOutlined />,
    color: 'pink',
    text: 'Pendente liberar boleto',
  },
  [OrderStatus.BILL_PAYMENT_PENDING]: {
    icon: <UserOutlined />,
    color: 'purple',
    text: 'Boleto pendente pgto',
  },
  [OrderStatus.HIRED]: {
    icon: <CheckOutlined />,
    color: 'green',
    text: 'Proposta implantada',
  },
  [OrderStatus.CANCELED]: {
    icon: <CloseOutlined />,
    color: 'red',
    text: 'Proposta cancelada',
  },
  [OrderStatus.DISPATCH_IN_PROGRESS]: {
    icon: <SettingOutlined />,
    color: 'pink',
    text: 'Em emissão automática',
  },
  [OrderStatus.NOT_MAPPED]: {
    icon: <InfoCircleOutlined />,
    color: 'red',
    text: 'Status não mapeado',
  },
}

const brokerStatus = {
  [BrokerStatus.PENDING]: {
    icon: <ClockCircleOutlined />,
    color: 'orange',
    text: 'Cadastro incompleto',
  },
  [BrokerStatus.COMPLETE]: {
    icon: <ClockCircleOutlined />,
    color: 'green',
    text: 'Cadastro completo',
  },
}

const getStatusAssets = (status: string, type: 'order' | 'broker') => {
  if (type === 'broker') return brokerStatus[status]

  return orderStatus[status]
}

const StatusTag: React.FC<Props> = ({ status, type = 'order' }) => {
  try {
    const { icon, color, text } = getStatusAssets(status, type)

    return (
      <StyledTag bordered={false} icon={icon} color={color}>
        {text}
      </StyledTag>
    )
  } catch {
    return
  }
}

export default StatusTag
