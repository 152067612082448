import { ReactNode } from 'react'

import {
  BellFilled,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditFilled,
  InfoCircleOutlined,
  SettingOutlined,
  UserOutlined,
  WechatOutlined,
} from '@ant-design/icons'

interface StatusMapper {
  [key: string]: {
    label: string
    color: string
    backgroundColor: string
    icon: ReactNode
    disabled?: boolean
  }
}

export const statusMapperFn = (color?: boolean): StatusMapper => {
  const statusMapper: StatusMapper = {
    FILLING_IN_PROGRESS: {
      label: 'Em preenchimento',
      icon: <EditFilled />,
      color: '#4581DB',
      backgroundColor: '#1d39c',
    },
    OPPORTUNITY_FILLED: {
      label: 'Oportunidade preenchida',
      icon: <EditFilled />,
      color: '#4581DB',
      backgroundColor: '#1d39c',
    },
    DATA_CONFIRMATION: {
      label: 'Confirmação de dados',
      icon: <WechatOutlined />,
      color: '#C47D12',
      backgroundColor: '#FFF5E6',
    },
    FILLED: {
      label: 'Preenchido',
      icon: <CheckOutlined />,
      color: '#4581DB',
      backgroundColor: '#1d39c',
    },
    RISK_ANALYSIS: {
      label: 'Em análise de risco',
      icon: <BellFilled />,
      color: '#C47D12',
      backgroundColor: '#FFF5E6',
    },
    DISPATCH_APPROVED: {
      label: 'Aprovado para emissão',
      icon: <CheckOutlined />,
      color: '#4581DB',
      backgroundColor: '#1d39c',
    },
    PENDING_DISPATCH: {
      label: 'Pendente emissão',
      icon: <BellFilled />,
      color: '#C47D12',
      backgroundColor: '#FFF5E6',
    },
    INSURER_PROCESSING: {
      label: 'Em emissão na operadora',
      icon: <EditFilled />,
      color: '#D967B9',
      backgroundColor: '#FFF1FB',
    },
    QUOTATION_INSURER: {
      label: 'Cotação Operadora',
      icon: <SettingOutlined />,
      color: '#D967B9',
      backgroundColor: '#FFF1FB',
    },
    DISPATCH_IN_PROGRESS: {
      label: 'Em emissão automática',
      icon: <SettingOutlined />,
      color: '#D967B9',
      backgroundColor: '#FFF1FB',
      disabled: true,
    },
    CUSTOMER_ACCEPTANCE: {
      label: 'Preenchimento DS e Aceite',
      icon: <UserOutlined />,
      color: '#7A59D7',
      backgroundColor: '#FBF1FF',
    },
    READY_TO_DISPATCH: {
      label: 'Liberar na operadora',
      icon: <BellFilled />,
      color: '#C47D12',
      backgroundColor: '#FFF5E6',
    },
    ANALYSIS: {
      label: 'Proposta em análise',
      icon: <ClockCircleOutlined />,
      color: '#D967B9',
      backgroundColor: '#FFF1FB',
    },
    QUALIFIED_INTERVIEW: {
      label: 'Entrevista qualificada',
      icon: <UserOutlined />,
      color: '#7A59D7',
      backgroundColor: '#FBF1FF',
    },
    FILLING_NOT_MEET_REQUIREMENTS: {
      label: 'Pendência na proposta',
      icon: <BellFilled />,
      color: '#C47D12',
      backgroundColor: '#FFF5E6',
    },
    EFFECTIVE_DATE_PENDING: {
      label: 'Data de vigência pendente',
      icon: <BellFilled />,
      color: '#C47D12',
      backgroundColor: '#FFF5E6',
    },
    WAITING_SIGNATURE: {
      label: 'Assinatura do contrato',
      icon: <UserOutlined />,
      color: '#7A59D7',
      backgroundColor: '#FBF1FF',
    },
    WAITING_BILL: {
      label: 'Pendente liberar boleto',
      icon: <ClockCircleOutlined />,
      color: '#D967B9',
      backgroundColor: '#FFF1FB',
    },
    BILL_PAYMENT_PENDING: {
      label: 'Boleto pendente pgto',
      icon: <UserOutlined />,
      color: '#7A59D7',
      backgroundColor: '#FBF1FF',
    },
    HIRED: {
      label: 'Proposta implantada',
      icon: <CheckOutlined />,
      color: 'green',
      backgroundColor: '#F1FAF3',
    },
    CANCELED: {
      label: 'Proposta cancelada',
      icon: <CloseOutlined />,
      color: 'red',
      backgroundColor: '#FAEEF0',
    },
    NOT_MAPPED: {
      label: 'Status não mapeado',
      color: '#C23535',
      backgroundColor: '#FAEEF0',
      icon: <InfoCircleOutlined />,
    },
  }

  if (color) {
    statusMapper.WAITING_SIGNATURE.icon = <UserOutlined style={{ color: '#7A59D7' }} />
    statusMapper.FILLING_IN_PROGRESS.icon = <EditFilled style={{ color: '#4581DB' }} />
    statusMapper.BILL_PAYMENT_PENDING.icon = <UserOutlined style={{ color: '#7A59D7' }} />
    statusMapper.HIRED.icon = <ClockCircleOutlined style={{ color: '#42A859' }} />
  }

  return statusMapper
}
