import { useEffect, useState } from 'react'

import { OnFileUploadInput } from 'design-system/Upload/Upload'
import { DocumentTable, Skeleton } from 'design-system/components'
import { MAX_FILE_SIZE_BYTES } from 'design-system/validationValues'

import { DocumentDto, Entity } from '@/App/clients/healthPlansOrders/dtos'
import { useGenerateSignedUrl } from '@/App/clients/healthPlansOrders/mutations/generateSignedUrl'
import { useUploadFileByURL } from '@/App/clients/healthPlansOrders/mutations/uploadFileByUrl'
import { normalizeFilename } from '@/App/helpers/normalizeFileNames'
import { EntityType } from '@/App/pages/orderDetails/entities/entityAccordion'
import { DocumentOcrUploadModal } from '@/App/pages/orderDetails/entities/entityAccordion/accordion/documentOcrUploadoModal'
import { EntityTypeLocale } from '@/App/pages/orderDetails/entities/entityAccordion/helper/helper'

import { AccordionSubtitles, HorizontalLine, StyledWarning } from './style'

interface CustomDocumentTableProps {
  entityData?: Entity | null
  selectedDocument: string
  isLoadindDownloadDocumentByPreviewURL: boolean
  isLoadingDeleteDocument: boolean
  deleteDocument: (documentId: string, isLegalRepresentative?: boolean) => void
  downloadDocument: (document: DocumentDto) => void
  isDocumentInspectorVisible: boolean
  setIsDocumentInspectorVisible: React.Dispatch<React.SetStateAction<boolean>>
  entityType: EntityType
  insurer?: string | null
  orderId?: string | null
  reloadOrderData?: () => void
  isLoadingReloadOrderData: boolean
  validateOrderDispatch?: boolean
  isOcrProcessing: boolean
  setIsOcrProcessing: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: boolean
}

export const CustomDocumentTable: React.FC<CustomDocumentTableProps> = ({
  entityData,
  selectedDocument,
  isLoadindDownloadDocumentByPreviewURL,
  isLoadingDeleteDocument,
  downloadDocument,
  isDocumentInspectorVisible,
  setIsDocumentInspectorVisible,
  entityType,
  insurer,
  orderId,
  reloadOrderData,
  isLoadingReloadOrderData,
  validateOrderDispatch,
  isOcrProcessing,
  setIsOcrProcessing,
  deleteDocument,
  disabled,
}) => {
  const [uploadedDocumentFileById, setUploadedDocumentFileById] = useState<OnFileUploadInput>()
  const [isDocumentUploadModalVisible, setIsDocumentUploadModalVisible] = useState(false)

  const [uploadedDocumentId, setUploadedDocumentId] = useState<string>()
  const [documentUploaded, setDocumentUploaded] = useState<DocumentDto>()
  const [isViewOcrActivated, setIsViewOcrActivated] = useState<boolean>()

  const uploadedDocuments: OnFileUploadInput[] = []

  const entityDocuments = entityData?.documents ?? []
  const externalLegalRepresentativeDocuments = entityData?.externalLegalRepresentative?.documents
  const entityName = entityData?.name

  useEffect(() => {
    if (uploadedDocumentFileById) {
      uploadedDocuments.push(uploadedDocumentFileById)
    }
  }, [uploadedDocumentFileById])

  const generateSignedUrlMutation = useGenerateSignedUrl()
  const uploadFileByUrlMutation = useUploadFileByURL()

  useEffect(() => {
    if (!isLoadingReloadOrderData) {
      setIsOcrProcessing(false)
    }
  }, [isLoadingReloadOrderData])

  useEffect(() => {
    if (
      !isLoadingReloadOrderData &&
      uploadFileByUrlMutation.isSuccess &&
      uploadedDocumentId != undefined
    ) {
      entityType === 'COMPANY'
        ? openOcrModal(externalLegalRepresentativeDocuments)
        : openOcrModal(entityDocuments)
    }
  }, [uploadFileByUrlMutation.isSuccess, isLoadingReloadOrderData])

  useEffect(() => {
    if (documentUploaded != undefined) {
      ;[
        'RG',
        'CNH',
        'Documento de Identificação',
        'Documento de Identificação*',
        'Documento de identificação com Foto*',
        'Carta de permanência',
        'Carta de Permanência',
        'Carta do Plano Saúde Anterior',
        'Carta do Plano Dental Anterior',
        'Comprovante de residência',
      ].includes(documentUploaded.type ?? '') && setIsDocumentUploadModalVisible(true)
      setUploadedDocumentId(undefined)
    }
  }, [documentUploaded])

  useEffect(() => {
    if (
      generateSignedUrlMutation.data?.uploadURL &&
      uploadedDocumentFileById?.fileContentType &&
      uploadedDocumentFileById.fileContent.size <= MAX_FILE_SIZE_BYTES
    ) {
      const uploadFileByUrlData = {
        uploadURL: generateSignedUrlMutation.data.uploadURL,
        data: uploadedDocumentFileById.fileContent,
        fileContentType: uploadedDocumentFileById.fileContentType,
      }
      uploadFileByUrlMutation.mutate(uploadFileByUrlData)

      reloadOrderData && reloadOrderData()
    }
  }, [generateSignedUrlMutation.data])

  const mainDocuments: DocumentDto[] = []
  const otherDocuments: DocumentDto[] = []

  for (const document of entityDocuments) {
    document?.type?.includes('*') ? mainDocuments.push(document) : otherDocuments.push(document)
  }

  const onFileUploadById = (document: DocumentDto, file: OnFileUploadInput) => {
    const normalizedFileName = normalizeFilename(file.fileName)

    setUploadedDocumentFileById(file)
    const onFileUploadData = {
      orderId: orderId,
      documentId: document.id,
      fileName: normalizedFileName,
    }
    if (file.fileContent.size <= MAX_FILE_SIZE_BYTES) {
      generateSignedUrlMutation.mutate(onFileUploadData)
      if (
        [
          'RG',
          'CNH',
          'Documento de Identificação',
          'Documento de Identificação*',
          'Documento de identificação com Foto*',
          'Carta de permanência',
          'Carta de Permanência',
          'Carta do Plano Saúde Anterior',
          'Carta do Plano Dental Anterior',
          'Comprovante de residência',
        ].includes(document.type ?? '')
      ) {
        setUploadedDocumentId(document.id)
      }
    }
  }

  const onViewOCRDocument = (document: DocumentDto) => {
    setDocumentUploaded(document)
    setIsDocumentUploadModalVisible(true)
    setIsViewOcrActivated(true)
  }

  const openOcrModal = (docs?: DocumentDto[]) => {
    docs?.forEach((doc) => {
      doc.id === uploadedDocumentId && setDocumentUploaded(doc)
    })
  }

  if (entityDocuments.length > 0)
    return (
      <>
        {generateSignedUrlMutation.isPending ||
        uploadFileByUrlMutation.isPending ||
        isLoadingReloadOrderData ? (
          <Skeleton />
        ) : (
          <>
            {mainDocuments.length > 0 && (
              <DocumentTable
                data={mainDocuments.map((document) => {
                  return {
                    id: document.id,
                    previewURL: document.previewURL,
                    fileName: document.fileName,
                    type: document.type,
                    status: document.status,
                    isLoadingDownloadDocument:
                      document.id == selectedDocument && isLoadindDownloadDocumentByPreviewURL,
                    isLoadingDeleteDocument:
                      document.id == selectedDocument && isLoadingDeleteDocument,
                    required:
                      validateOrderDispatch && !mainDocuments.every((doc) => doc.fileName !== null),
                  }
                })}
                entityType={EntityTypeLocale[entityType]}
                entityName={entityName ?? 'Sem nome preenchido'}
                isInspectorOpen={isDocumentInspectorVisible}
                setInspectorOpen={setIsDocumentInspectorVisible}
                onDownloadImageFromInspectorClick={(doc) => downloadDocument(doc as DocumentDto)}
                isLoadingDownloadImageFromInspector={isLoadindDownloadDocumentByPreviewURL}
                onDownloadClick={(doc) => downloadDocument(doc as DocumentDto)}
                onAttachmentClick={(doc, file) =>
                  onFileUploadById(doc as DocumentDto, file as OnFileUploadInput)
                }
                onOCRClick={(doc) => onViewOCRDocument(doc as DocumentDto)}
                isExternal={true}
                firstColumn="type"
                onDeleteClick={deleteDocument}
                disabled={disabled}
              />
            )}
            {otherDocuments.length > 0 && (
              <>
                <AccordionSubtitles>Outros Documentos</AccordionSubtitles>
                <HorizontalLine />
                {validateOrderDispatch &&
                  otherDocuments.every(
                    (doc) => doc.fileName === null && entityType === 'COMPANY',
                  ) && (
                    <StyledWarning>
                      *Obrigatório preencher ao menos um campo em outros documentos.
                    </StyledWarning>
                  )}
                <DocumentTable
                  data={otherDocuments.map((document) => {
                    return {
                      id: document.id,
                      previewURL: document.previewURL,
                      fileName: document.fileName,
                      type: document.type,
                      status: document.status,
                      isLoadingDownloadDocument:
                        document.id == selectedDocument && isLoadindDownloadDocumentByPreviewURL,
                      isLoadingDeleteDocument:
                        document.id == selectedDocument && isLoadingDeleteDocument,
                    }
                  })}
                  entityType={EntityTypeLocale[entityType]}
                  entityName={entityName ?? 'Sem nome preenchido'}
                  isInspectorOpen={isDocumentInspectorVisible}
                  setInspectorOpen={setIsDocumentInspectorVisible}
                  onDownloadImageFromInspectorClick={(doc) => downloadDocument(doc as DocumentDto)}
                  isLoadingDownloadImageFromInspector={isLoadindDownloadDocumentByPreviewURL}
                  onDownloadClick={(doc) => downloadDocument(doc as DocumentDto)}
                  onAttachmentClick={(doc, file) =>
                    onFileUploadById(doc as DocumentDto, file as OnFileUploadInput)
                  }
                  onOCRClick={(doc) => onViewOCRDocument(doc as DocumentDto)}
                  onDeleteClick={deleteDocument}
                  isExternal={true}
                  firstColumn="type"
                  disabled={disabled}
                />
              </>
            )}

            {entityData?.externalLegalRepresentative?.documents &&
              entityData.externalLegalRepresentative.documents.length > 0 && (
                <>
                  <AccordionSubtitles>Documento Representante legal</AccordionSubtitles>
                  <HorizontalLine />
                  {validateOrderDispatch &&
                    entityData.externalLegalRepresentative.documents.every(
                      (doc) => doc.fileName === null && entityType === 'COMPANY',
                    ) && (
                      <StyledWarning>
                        *Obrigatório preencher ao menos um campo em outros documentos.
                      </StyledWarning>
                    )}
                  <DocumentTable
                    data={entityData.externalLegalRepresentative.documents.map((document) => {
                      return {
                        id: document.id,
                        previewURL: document.previewURL,
                        fileName: document.fileName,
                        type: document.type,
                        status: document.status,
                        isLoadingDownloadDocument:
                          document.id == selectedDocument && isLoadindDownloadDocumentByPreviewURL,
                        isLoadingDeleteDocument:
                          document.id == selectedDocument && isLoadingDeleteDocument,
                      }
                    })}
                    entityType={EntityTypeLocale[entityType]}
                    entityName={entityName ?? 'Sem nome preenchido'}
                    isInspectorOpen={isDocumentInspectorVisible}
                    setInspectorOpen={setIsDocumentInspectorVisible}
                    onDownloadImageFromInspectorClick={(doc) =>
                      downloadDocument(doc as DocumentDto)
                    }
                    isLoadingDownloadImageFromInspector={isLoadindDownloadDocumentByPreviewURL}
                    onDownloadClick={(doc) => downloadDocument(doc as DocumentDto)}
                    onAttachmentClick={(doc, file) =>
                      onFileUploadById(doc as DocumentDto, file as OnFileUploadInput)
                    }
                    onOCRClick={(doc) => onViewOCRDocument(doc as DocumentDto)}
                    isExternal={true}
                    firstColumn="type"
                    onDeleteClick={(docId) => deleteDocument(docId, true)}
                    disabled={disabled}
                  />
                </>
              )}
          </>
        )}

        <DocumentOcrUploadModal
          visibility={isDocumentUploadModalVisible}
          setVisibility={setIsDocumentUploadModalVisible}
          orderId={orderId}
          entityData={entityData}
          documentUploaded={documentUploaded}
          setDocumentUploaded={setDocumentUploaded}
          reloadOrderData={reloadOrderData}
          isViewOcrActivated={isViewOcrActivated ?? false}
          insurer={insurer}
          isOcrProcessing={isOcrProcessing}
          setIsOcrProcessing={setIsOcrProcessing}
          entityType={entityType}
        />
      </>
    )
}
