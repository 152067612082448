import { type QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import { api } from '../../http'
import type { InsurerEdgeFieldType, InsurerEdgeFields } from '../dto'

type insurerFieldsHookProps = {
  enabled?: boolean
  fieldType: InsurerEdgeFieldType
  insurerId?: string
  product?: string
  modality?: string
  issuedByBroker?: number
}

type GetInsurerFieldsProps = QueryFunctionContext & {
  fieldType: InsurerEdgeFieldType
  insurerId?: string
  product?: string
  modality?: string
  issuedByBroker?: number
}

async function getInsurerFields({
  fieldType,
  insurerId,
  product,
  modality,
  issuedByBroker,
  signal,
}: GetInsurerFieldsProps) {
  const response = await api.get<{ result: Array<InsurerEdgeFields> }>(
    `${import.meta.env.VITE_BLISS_INSURER_EDGE_URL}/fields`,
    {
      signal,
      params: { type: fieldType, insurerId, product, modality, issuedByBroker },
      headers: { 'x-api-key': import.meta.env.VITE_BLISS_INSURER_EDGE_API_KEY },
    },
  )

  return response.data.result ?? null
}

export const getInsurerFieldsOptions = ({
  enabled,
  fieldType,
  insurerId,
  product,
  modality,
  issuedByBroker,
}: insurerFieldsHookProps) => {
  return queryOptions({
    queryKey: ['insurerFields', { fieldType, insurerId, product, modality, issuedByBroker }],
    queryFn: (params) =>
      getInsurerFields({ ...params, fieldType, insurerId, product, modality, issuedByBroker }),
    enabled: !!fieldType && !!insurerId && !!product && !!modality && enabled,
    select(data) {
      return data.map((field) => {
        const newOptions = field.options.filter((item, index, self) => {
          return (
            index ===
            self.findIndex(
              (t) =>
                t.type === item.type &&
                t.blissValue === item.blissValue &&
                t.intranetValue === item.intranetValue,
            )
          )
        })

        return {
          ...field,
          options: newOptions,
        }
      })
    },
  })
}

export const useInsurerFields = (params: insurerFieldsHookProps) => {
  return useQuery(getInsurerFieldsOptions(params))
}
