import { Entity, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import type { EntityType } from '../..'
import { getRequiredEntityFields } from '../helper'

export const getRequiredFieldsPorto = (orderData?: GetOrderInformationBody) => {
  const dynamicFormValidator: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = [getRequiredEntityFields(orderData?.quotation, ['totalAmount', 'effectiveDate'])]

  if (orderData?.quotation?.type === 'COMPANY') {
    dynamicFormValidator.push(
      getRequiredEntityFields(orderData?.company, [
        'document',
        'address.zipCode',
        'address.number',
        'address.street',
        'address.neighborhood',
        'address.state',
        'tradingName',
        'registrationStatus',
        'openingDate',

        // contact
        'phone',
        'name',
        'mail',

        // externalLegalRepresentative,
        'externalLegalRepresentative.name',
        'externalLegalRepresentative.mail',
        'externalLegalRepresentative.cpf',
        'externalLegalRepresentative.role',
        'externalLegalRepresentative.phone',
      ]),
    )
  }

  if (orderData?.holders && orderData.holders.length > 0) {
    for (const holder of orderData.holders) {
      const { requiredFields } = getRequiredEntityFieldsPorto('HOLDER', holder, orderData)
      dynamicFormValidator.push(...requiredFields)

      const dependents = holder.dependents ?? []
      for (const dependent of dependents) {
        const { requiredFields } = getRequiredEntityFieldsPorto('DEPENDENT', dependent, orderData)
        dynamicFormValidator.push(...requiredFields)
      }
    }
  }

  return dynamicFormValidator.filter((value) => value)
}

export const getRequiredCompanyFieldsPorto = (orderData?: GetOrderInformationBody) => {
  return [
    orderData?.company?.document,
    orderData?.company?.registrationStatus,
    orderData?.company?.openingDate,
    orderData?.company?.address?.zipCode,
    orderData?.company?.address?.number,

    orderData?.company?.name,
    orderData?.company?.phone,
    orderData?.company?.mail,

    orderData?.company?.externalLegalRepresentative?.name,
    orderData?.company?.externalLegalRepresentative?.mail,
    orderData?.company?.externalLegalRepresentative?.cpf,
    orderData?.company?.externalLegalRepresentative?.role,
    orderData?.company?.externalLegalRepresentative?.phone,
  ]
}

export const getRequiredHolderGroupFieldsPorto = (orderData?: GetOrderInformationBody) => {
  const holderList = orderData?.holders?.map((_, index) => {
    return [
      orderData?.holders?.at(index)?.name,
      orderData?.holders?.at(index)?.maritalStatus,
      orderData?.holders?.at(index)?.contractType,
      orderData?.holders?.at(index)?.contractDate,
      orderData?.holders?.at(index)?.gender,
      orderData?.holders?.at(index)?.cpf,
      orderData?.holders?.at(index)?.rg,
      orderData?.holders?.at(index)?.issuingAgency,
      orderData?.holders?.at(index)?.issuingDate,
      orderData?.holders?.at(index)?.birthDate,
      orderData?.holders?.at(index)?.phone,
      orderData?.holders?.at(index)?.mail,
      orderData?.holders?.at(index)?.isAdministrativeLife,
      orderData?.holders?.at(index)?.address?.zipCode,
      orderData?.holders?.at(index)?.address?.number,
      ...(orderData?.quotation?.product === 'HEALTH'
        ? [
            orderData?.holders?.at(index)?.healthPlan?.catalogPlanId,
            orderData?.holders?.at(index)?.healthPlan?.coparticipation,
          ]
        : []),
      ...(orderData?.quotation?.product === 'DENTAL'
        ? [
            orderData?.holders?.at(index)?.dentalPlan?.catalogPlanId,
            orderData?.holders?.at(index)?.dentalPlan?.coparticipation,
          ]
        : []),
      ...(orderData?.holders?.at(index)?.hiredHealthPlan?.isActive
        ? [orderData?.holders?.at(index)?.hiredHealthPlan?.activeHiredPlanInsurer]
        : []),
    ]
  })

  const allDependents = getRequiredDependentFieldsPorto(orderData)

  return [...(holderList?.at(0) ?? []), ...allDependents]
}

export const getRequiredDependentFieldsPorto = (orderData?: GetOrderInformationBody) => {
  const dependentList =
    orderData?.holders?.map((holder) =>
      holder?.dependents?.map((dependent: Entity) => [
        dependent.name,
        dependent.holderKinship,
        dependent.maritalStatus,
        dependent.gender,
        dependent.cpf,
        dependent.rg,
        dependent.issuingAgency,
        dependent.issuingDate,
        dependent.birthDate,
        dependent.isAdministrativeLife,
        ...(dependent.holderKinship === 'MARRIED' ? [dependent.marriageDate] : []),
        ...(dependent.hiredHealthPlan?.isActive
          ? [dependent.hiredHealthPlan.activeHiredPlanInsurer]
          : []),
      ]),
    ) ?? []

  return dependentList.flat(2)
}

export const getRequiredEntityFieldsPorto = (
  entityType: EntityType,
  entity?: Entity | null,
  orderData?: GetOrderInformationBody,
) => {
  const requiredFields: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = []

  if (entityType === 'HOLDER') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'name',
        'maritalStatus',
        'contractType',
        'contractDate',
        'gender',
        'cpf',
        'rg',
        'issuingAgency',
        'issuingDate',
        'birthDate',
        'phone',
        'mail',
        'isAdministrativeLife',
        'address.zipCode',
        'address.number',
        'motherName',
        ...(entity?.maritalStatus === 'MARRIED' ? ['marriageDate'] : []),
        ...(orderData?.quotation?.product === 'HEALTH'
          ? ['healthPlan.catalogPlanId', 'healthPlan.coparticipation']
          : []),
        ...(orderData?.quotation?.product === 'DENTAL'
          ? ['dentalPlan.catalogPlanId', 'dentalPlan.coparticipation']
          : []),
        ...(entity?.hiredHealthPlan?.isActive
          ? [
              'hiredHealthPlan.activeHiredPlanInsurer',
              'hiredHealthPlan.lastPaymentAt',
              'hiredHealthPlan.hiredAt',
            ]
          : []),
      ]),
    )
  }

  if (entityType === 'DEPENDENT') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'name',
        'holderKinship',
        'maritalStatus',
        'gender',
        'cpf',
        'rg',
        'issuingAgency',
        'issuingDate',
        'birthDate',
        'isAdministrativeLife',
        'motherName',
        ...(entity?.maritalStatus === 'MARRIED' ? ['marriageDate'] : []),
        ...(entity?.hiredHealthPlan?.isActive
          ? [
              'hiredHealthPlan.activeHiredPlanInsurer',
              'hiredHealthPlan.lastPaymentAt',
              'hiredHealthPlan.hiredAt',
            ]
          : []),
      ]),
    )
  }

  return {
    requiredFields,
    hasEmptyValues:
      requiredFields?.some(
        (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
      ) ?? false,
  }
}
